<template>
    <div class="history">
        <div class="content">
            <el-timeline>
                <el-timeline-item :hide-timestamp="true" v-for="item in historyList" :key="item.id" size="large">
                    <div class="timestamp" @click="toDetail(item)">
                        {{ item.version_name }}
                    </div>
                    <el-card>
                        <div class="explain" v-html="item.explain"></div>
                        <p class="author">{{item.user_name}} 提交于 {{item.upload_time}}</p>
                        <button class="edit btn" @click="editClick(item)">编辑</button>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>

        <EditPage :open.sync="open" :form="form" @getTableData="getTableData"></EditPage>

    </div>
</template>

<script>
import { getHistoryList } from '@/api/appAPI.js';
import EditPage from '@/components/editPage';

export default {
    components: {
        EditPage
    },
    data(){
        return {
            historyList: [],
            open: false,
            form: {},
        }
    },
    computed: {
        id(){
            return this.$route.query.id;
        },
    },
    created(){
        this.getTableData();
    },
    methods: {
        getTableData(){
            const params = {
                id: this.id
            }
            getHistoryList(params).then(res => {
                res.forEach(item => item.upload_time = Date.dateFormat(item.upload_time))
                this.historyList = res;
            })
        },
        editClick(data){
            // console.log(data);
            this.form = this.initForm(data);
            this.open = true;
        },
        initForm(data){
            const form = {
                id: data.id,
                type: data.type,
                version_name: data.version_name,
                name: data.name,
                unique_name: data.unique_name,
                status: data.status,
                force_update: data.force_update,
                explain: data.explain,
                pkg: data.pkg,
                version: data.version,
                extra: data.extra && JSON.parse(data.extra) || {
                    area: []
                },
            }
            return form;
        },
        toDetail(data){
            const routeData = this.$router.resolve({
                name: "Detail",
                query: {
                    id: data.id,
                }
            });
            window.open(routeData.href, '_blank');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.history{
    padding-top: 20px;
    overflow: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .content{
        width: 80%;
        margin: 0 auto;
    }
    .explain{
        white-space: pre;
        line-height: 1.4;
        color: #999;

    }
    .author{
        margin-top: 25px;
        color: #999;
    }
    .edit{
        position: absolute;
        top: 40px;
        right: 10px;
        color: #337ab7;
        display: none;
    }
    /deep/ .el-card__body:hover{
        .edit{
            display: inline-block;
        }
    }
    .timestamp{
        padding-top: 4px;
        margin-bottom: 8px;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        color: #337ab7;
    }
}

</style>